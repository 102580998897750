import { storage } from "../firebase";
import { v4 as uuidv4 } from "uuid";

class FirebaseUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    // The FileReader instance
    this.reader = new FileReader();
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          this._initRequest(file);
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.task) {
      this.task.cancel();
    }
  }

  // Initializes the Firebase storage upload.
  _initRequest(file) {
    const extension = file.name.split(".").pop();
    this.filename = uuidv4() + "." + extension;
    this.ref = storage.ref().child("articles/" + this.filename);
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    this.reader.addEventListener("error", () => reject(genericErrorText));
    this.reader.addEventListener("abort", () => reject());
    this.reader.addEventListener(
      "load",
      () => {
        this.task = this.ref.put(file);

        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if (this.task) {
          this.task.on(
            "state_changed",
            snapshot => {
              loader.uploadTotal = snapshot.totalBytes;
              loader.uploaded = snapshot.bytesTransferred;
            },
            error => {
              reject(error);
            },
            () => {
              console.log(this.task);
              this.task.snapshot.ref.getDownloadURL().then(url => {
                // If the upload is successful, resolve the upload promise with an object containing
                // at least the "default" URL, pointing to the image on the server.
                // This URL will be used to display the image in the content. Learn more in the
                // UploadAdapter#upload documentation.
                resolve({
                  default: url
                });
              });
            }
          );
        }
      },
      false
    );
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Send the request.
    this.reader.readAsBinaryString(file);
  }
}

function FirebaseUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    return new FirebaseUploadAdapter(loader);
  };
}

export default FirebaseUploadAdapterPlugin;
