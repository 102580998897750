<template>
  <div class="wrapper">
    <b-container fluid v-if="loading">
      <b-row>
        <b-col class="d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="full-height" fluid v-if="!loading">
      <b-row class="full-height">
        <b-col class="full-height">
          <div class="card card-shadow my-3">
            <b-container class="full-height" fluid>
              <b-row>
                <b-col class="text-left">
                  <div class="card-body">
                    <h5 class="card-title">{{ article.name }}</h5>
                    <small class="text-muted"
                      ><b>Location:</b> {{ friendlyPath }}</small
                    >
                  </div>
                </b-col>
                <b-col class="text-right">
                  <div class="card-body">
                    <b-button
                      variant="outline-danger"
                      @click="closeEditor"
                      size="sm"
                      class="mr-1"
                      ><i class="far fa-window-close"></i
                    ></b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="editor-row">
                <b-col class="full-height">
                  <div class="card-body" v-html="article.text"></div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";

export default {
  name: "ArticlePreviewer",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader
  },
  data() {
    return {
      bind: [{ ref: "articles", target: "articles" }],
      loading: false,
      saving: false,
      uploading: false,
      articleKey: null,
      article: {
        name: "",
        description: "",
        text: ""
      },
      path: [],
      friendlyPath: ""
    };
  },
  watch: {
    path: {
      immediate: false,
      handler(path) {
        this.bindObject(path, null, "article");
      }
    }
  },
  methods: {
    closeEditor() {
      window.close();
    },
    async setFriendlyPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      segments.pop();
      let root = ["articles"];
      let path = [];
      for (const segment of segments) {
        if (segment.startsWith("-")) {
          const snapshot = await this.getObjectSnapshot(
            root.join("/"),
            segment,
            true
          );
          const category = snapshot.val();
          root.push(segment, "articles");
          path.push(category.name);
        }
      }
      this.friendlyPath = "/" + path.join("/");
    }
  },
  created() {
    if (this.$route.params.path !== undefined) {
      this.path = this.$route.params.path;
      this.articleKey = this.path.split("/").pop();
      this.setFriendlyPath(this.path);
    }
  }
};
</script>

<style>
main {
  height: calc(100% - 70px);
}

#content {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}
</style>

<style scoped>
.wrapper {
  max-width: 1000px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.full-height {
  min-height: 100%;
}

.card {
  min-height: calc(100% - 2rem);
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.editor-row {
  min-height: calc(100% - 100px);
}
</style>
